import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import BackgroundImage from "gatsby-background-image"

import Header from "../header/index"
import "./layout.css"

import JobSlider from "../jobSlider"

import Footer from "../footer"

const Layout = ({ children, showNav, page, currency, isBase }) => {
  const data = useStaticQuery(graphql`
    query test {
      site {
        siteMetadata {
          title
        }
      }
      file(relativePath: { eq: "Background-New-01.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      {/* <BackgroundImage
        Tag="section"
        backgroundColor={`#040e18`}
        className={"background-img-custom"}
        // fluid={data.file.childImageSharp?.fluid}
      > */}
      <Header
        page={page}
        currency={currency}
        isBase={isBase}
        showNav={showNav}
        siteTitle={data.site.siteMetadata?.title || `Title`}
      />
      {children}
      <footer>
        <Footer showNav={showNav} />
      </footer>
      <JobSlider />
      {/* </BackgroundImage> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showNav: PropTypes.bool,
}

export default Layout
