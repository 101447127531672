export default [
  {
    question: "What industries do you specialise in?",
    answer:
      "Referment is a specialist FinTech recruitment platform, covering Front, Middle and Back office roles in London, Australia and North America. We focus on six main streams; FinTech, Buy Side, Trading Venues & Exchanges, Sell Side, Advisory/Consultancies, Technology Providers",
  },
  {
    question: "What kind of roles do you post?",
    answer:
      "Our clients mainly use Referment to fill technology, sales, marketing and operations roles. The roles we post are usually suitable for FinTech professionals with 2+ years experience with AUD$70,000 - AUD$250,000 base salaries.",
  },
  {
    question: "What is a referral?",
    answer:
      "It’s not what you know, it’s who you know. Referring is when you introduce us to someone from your network via the Referment platform. If you see a new Product Manager job and you know someone with Product Management experience in a relevant industry, you can refer them to us by letting us know their name and contact details so we can get in touch to discuss the role in more detail.",
  },
  {
    question: "How do I make a referral?",
    answer:
      "Referring someone you know to a new role has never been easier. The whole process takes around 30 seconds, as long as you have their details to hand and we then take it from there.",
  },
  {
    question: "How do referral rewards work?",
    answer:
      "You get rewarded when your referral completes a first-round interview with one of our clients and the more you refer the more you earn. You'll get AUD$450 for your first successful referral and then move up a level to Bronze where you'll get AUD$500 and once you've earned that you'll move up to Silver and get AUD$550. Each level moves you up AUD$50 until you hit Referminator status where you'll earn AUD$700 per successful referral!",
  },
  {
    question: "What rewards can I get?",
    answer:
      "You can choose from the following rewards: a PayPal transfer, vouchers from Amazon, Apple, John Lewis, Selfridges, Harrods, Eurostar, Virgin Experience Days, Eurostar and Four Seasons or credit for Uber and Uber Eats.",
  },
  {
    question: "When will I get rewarded?",
    answer:
      "We’ll get in touch within 24 hours of your referral completing a first-round interview with one of our clients and aim to have your referral reward to you within 48 hours.",
  },
  {
    question: "Can I apply myself?",
    answer:
      "Yes! Just click Apply on any of the roles on the platform and let us know how we can get in touch.",
  },
  {
    question: "Do I get rewarded if I apply?",
    answer:
      "No, rewards are exclusively for community members that make successful referrals.",
  },
  {
    question: "What do you do with my referral’s information?",
    answer:
      "We’ll use the information you’ve provided to contact the candidate and talk them through the opportunity. If they don’t get back to us, we may ask you to give them a nudge. We never share any data on you or your referral with third parties without express permission.",
  },
  {
    question: "What is a successful referral?",
    answer:
      "Successful referrals are where your referral completes a first-round interview with the hiring manager at one of our clients. Telephone screens and tech tests don't count towards rewards.",
  },
]
