import React, { useState } from "react"
import FaqBox from "../faqBox"
import data1 from "../ukdata"
import data2 from "../audata"

export default ({ currency }) => {
  const [selected, setSelect] = useState(-1)

  const [data, setData] = useState(currency === "au" ? data2 : data1)
  const setSelected = index => {
    if (index === selected) {
      setSelect(-1)
    } else {
      setSelect(index)
    }
  }

  return (
    <div className={"container pt-40 pb-10 lg:px-24 mx-auto"}>
      <div className={"heading-center mb-8 m-4"}>
        Your questions answered<span className={"red"}>.</span>
      </div>
      <div className={"flex flex-col"}>
        <div className={"flex flex-col md:grid md:grid-cols-2"}>
          <FaqBox
            onClick={() => setSelected(0)}
            selected={selected}
            index={0}
          />
          <FaqBox
            onClick={() => setSelected(1)}
            selected={selected}
            index={1}
          />
        </div>
        <div>
          {selected === 0 || selected === 1 ? (
            <div className={"md:block hidden bg-primaryLight m-4 p-8"}>
              <div className={"text-xl tracking-wide font-semibold mb-2"}>
                {data[selected].question}
              </div>
              <div className={"text-lg tracking-wide font-light"}>
                {data[selected].answer}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className={"flex flex-col"}>
        <div className={"flex flex-col md:grid md:grid-cols-2"}>
          <FaqBox
            onClick={() => setSelected(2)}
            selected={selected}
            index={2}
          />
          <FaqBox
            onClick={() => setSelected(3)}
            selected={selected}
            index={3}
          />
        </div>
        <div>
          {selected === 2 || selected === 3 ? (
            <div className={"md:block hidden bg-primaryLight m-4 p-8"}>
              <div className={"text-xl tracking-wide font-semibold mb-2"}>
                {data[selected].question}
              </div>
              <div className={"text-lg tracking-wide font-light"}>
                {data[selected].answer}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className={"flex flex-col"}>
        <div className={"flex flex-col md:grid md:grid-cols-2"}>
          <FaqBox
            onClick={() => setSelected(4)}
            selected={selected}
            index={4}
          />
          <FaqBox
            onClick={() => setSelected(5)}
            selected={selected}
            index={5}
          />
        </div>
        <div>
          {selected === 4 || selected === 5 ? (
            <div className={"md:block hidden bg-primaryLight m-4 p-8"}>
              <div className={"text-xl tracking-wide font-semibold mb-2"}>
                {data[selected].question}
              </div>
              <div className={"text-lg tracking-wide font-light"}>
                {data[selected].answer}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className={"flex flex-col"}>
        <div className={"flex flex-col md:grid md:grid-cols-2"}>
          <FaqBox
            onClick={() => setSelected(6)}
            selected={selected}
            index={6}
          />
          <FaqBox
            onClick={() => setSelected(7)}
            selected={selected}
            index={7}
          />
        </div>
        <div>
          {selected === 6 || selected === 7 ? (
            <div className={"md:block hidden bg-primaryLight m-4 p-8"}>
              <div className={"text-xl tracking-wide font-semibold mb-2"}>
                {data[selected].question}
              </div>
              <div className={"text-lg tracking-wide font-light"}>
                {data[selected].answer}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className={"flex flex-col"}>
        <div className={"flex flex-col md:grid md:grid-cols-2"}>
          <FaqBox
            onClick={() => setSelected(8)}
            selected={selected}
            index={8}
          />
          <FaqBox
            onClick={() => setSelected(9)}
            selected={selected}
            index={9}
          />
        </div>
        <div>
          {selected === 8 || selected === 9 ? (
            <div className={"md:block hidden bg-primaryLight m-4 p-8"}>
              <div className={"text-xl tracking-wide font-semibold mb-2"}>
                {data[selected].question}
              </div>
              <div className={"text-lg tracking-wide font-light"}>
                {data[selected].answer}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className={"flex flex-col"}>
        <div className={"flex flex-col md:grid md:grid-cols-2"}>
          <FaqBox
            onClick={() => setSelected(10)}
            selected={selected}
            index={10}
          />
        </div>
        <div>
          {selected === 10 ? (
            <div className={"md:block hidden bg-primaryLight m-4 p-8"}>
              <div className={"text-xl tracking-wide font-semibold mb-2"}>
                {data[selected].question}
              </div>
              <div className={"text-lg tracking-wide font-light"}>
                {data[selected].answer}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
