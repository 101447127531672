import React from "react"

import Layout from "../components/layout/index"
import SEO from "../components/seo"

import Faq from "../components/faqComponents/faqLayout"

const ClientPage = props => {
  const [showNav, setShowNav] = React.useState(false)

  const [currency, setCurrency] = React.useState("uk")

  React.useEffect(() => {
    setCurrency(props.pathContext.currency || "uk")
  }, [])

  const onScroll = e => {
    if (e.target.scrollTop > 100) {
      setShowNav(true)
    } else {
      setShowNav(false)
    }
  }
  return (
    <>
      <SEO
        title="FAQ"
        description={
          "Frequently asked questions regarding referrals, applying to jobs and referral rewards"
        }
      />
      <Layout
        isBase={!props.pathContext.currency}
        showNav={showNav}
        page={"client"}
        currency={currency}
      >
        <div onScroll={e => onScroll(e)}>
          <Faq currency={currency} />
        </div>
      </Layout>
    </>
  )
}

export default ClientPage
