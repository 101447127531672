import React from "react"
import PropTypes from "prop-types"
import styles from "./index.module.css"
import data from "../ukdata"

const Box = ({ onClick, selected, index }) => {
  return (
    <>
      <div
        onKeyPress={() => onClick(index)}
        role={"button"}
        tabIndex="0"
        onClick={() => onClick(index)}
        className={
          index === selected ? styles.selectedContainer : styles.nonContainer
        }
      >
        <div className={styles.question}>{data[index].question}</div>
        <div className={index === selected ? styles.selectedAnswer : styles.answer}>{data[index].answer}</div>
      </div>
    </>
  )
}

Box.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.number,
  index: PropTypes.number,
}

export default Box
