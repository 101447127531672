import React from "react"
import "./index.scss"

export default () => {
  const get = () => {
    fetch("https://api.referment.com/v2/jobs")
      .then(e => e.json())
      .then(e => {
        setData(
          e
            .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
            .filter((x, i) => i < 10)
        )
      })
  }

  React.useEffect(() => {
    get()
  }, [])

  const [data, setData] = React.useState([])

  return (
    <>
      <div className="ticker-wrap hidden lg:block">
        <div className="ticker">
          {data.length
            ? data.map(x => (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://app.referment.com/referral/jobs/${x.id}`}
                  className="ticker__item"
                >
                  {x.title} | {x.sector} | {x.location}
                </a>
              ))
            : null}
        </div>
      </div>
    </>
  )
}
